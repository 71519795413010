import React, { useEffect } from "react"
import {
  Card,
  CardContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
  Button,
  Divider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { Link as ReactScroll } from "react-scroll"
import LocalizedLink from "./LocalizedLink"

const useStyles = makeStyles(theme => ({
  stickyCardHid: {
    bottom: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%) translateY(calc(100% - 64px))",
    width: "100%",
    zIndex: 1000,
    transition: "all 0.3s ease-in-out",
  },
  stickyCardOpen: {
    transform: "translateX(-50%) translateY(0)",
  },
  stickyCardTitle: {
    padding: "0.5rem",
    fontFamily: "MabryProLight",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    letterSpacing: "1.3px",
    textAlign: "center",
    fontWeight: 600,
  },
  stickyCardContent: {
    height: "100%",
    padding: 15,
    paddingTop: "0.5rem",
    "&:last-child": {
      paddingBottom: 0,
    },
    transition: "all 0.3s ease-in-out",
  },
  stickyCardGutter: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  stickyListIcon: {
    minWidth: 20,
    color: "unset",
  },
  servicesBox: {
    backgroundColor: "white",
    padding: "16px",
    transition: "all 0.3s ease-in-out",
  },
  servicesBoxOpen: {
    backgroundColor: "transparent",
    padding: "3px",
  },
  openServiceHid: {
    backgroundColor: theme.palette.secondary.main,
    color: "black",
    width: "100%",
    padding: "0",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "black",
    },
    transition: "all 0.3s ease-in-out",
  },
  openServiceOpen: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    padding: 0,
    height: "100%",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  denseList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const InvestStickyCard = ({
  title,
  servicesList,
  links,
  sections,
  selected,
  individualsTitle,
  companiesTitle,
  handleTabChange,
}) => {
  const classes = useStyles()

  const [openPanel, setOpenPanel] = React.useState(false)

  const handleOpenPanel = () => {
    setOpenPanel(!openPanel)
  }

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("scroll", () => {
        setOpenPanel(false)
      })
    }
  }, [])

  const tabChangeHelper = (newValue) => {
    handleTabChange(newValue)
    setOpenPanel(false)
  }

  return (
    <Hidden mdUp>
      <Card
        className={
          openPanel
            ? `${classes.stickyCardHid} ${classes.stickyCardOpen}`
            : classes.stickyCardHid
        }
      >
        <Box
          className={
            openPanel
              ? `${classes.servicesBox} ${classes.servicesBoxOpen}`
              : classes.servicesBox
          }
        >
          <Button
            className={
              openPanel ? classes.openServiceOpen : classes.openServiceHid
            }
            onClick={handleOpenPanel}
          >
            <Typography className={classes.stickyCardTitle}>{title}</Typography>
          </Button>
        </Box>
        <CardContent className={classes.stickyCardContent}>
          <List dense className={classes.denseList}>
            {servicesList ? (
              <>
                {servicesList.map((service, index) => (
                  <ListItem
                    key={index}
                    button
                    component={ReactScroll}
                    to={sections[index]}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    selected={selected === index}
                  >
                    <ListItemText primary={service} />
                  </ListItem>
                ))}
              </>
            ) : (
              <>
                <ReactScroll
                  to="section-tabs"
                  spy={true}
                  smooth={true}
                  offset={-30}
                  duration={500}
                >
                  <ListItem
                    key="indivuduals"
                    disableGutters
                    classes={{
                      dense: classes.denseList,
                      button: classes.button,
                    }}
                    button
                  >
                    <ListItemText
                      primartuTypographyProps={{ variant: "h6" }}
                      onClick={() => tabChangeHelper(false)}
                    >
                      {individualsTitle}
                    </ListItemText>
                  </ListItem>
                </ReactScroll>
                <ReactScroll
                  to="section-tabs"
                  spy={true}
                  smooth={true}
                  offset={-30}
                  duration={500}
                >
                  <ListItem
                    key="companies"
                    disableGutters
                    classes={{
                      dense: classes.denseList,
                      button: classes.button,
                    }}
                    button
                  >
                    <ListItemText
                      primartuTypographyProps={{ variant: "h6" }}
                      onClick={() => tabChangeHelper(true)}
                    >
                      {companiesTitle}
                    </ListItemText>
                  </ListItem>
                </ReactScroll>
              </>
            )}
          </List>
          <Divider
            style={{
              backgroundColor: "#000",
              height: "1px",
              margin: "0.5rem 0",
              marginTop: "1rem",
            }}
          />
          <List dense className={classes.denseList}>
            {links.map(({ label, to }, index) => (
              <>
                <ListItem
                  key={index}
                  component={LocalizedLink}
                  to={to}
                  divider={index >= links.length - 1 ? false : true}
                  classes={{
                    gutters: classes.stickyCardGutter,
                    button: classes.button,
                  }}
                  button
                  selected={to === selected ? true : false}
                >
                  <ListItemText>{label}</ListItemText>
                  <ListItemIcon className={classes.stickyListIcon}>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </ListItem>
              </>
            ))}
          </List>
        </CardContent>
      </Card>
    </Hidden>
  )
}

export default InvestStickyCard
