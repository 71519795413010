import React, { useState, useEffect} from "react"
import {
  Card,
  CardContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { Link as ReactScroll } from "react-scroll"
import LocalizedLink from "./LocalizedLink"

const useStyles = makeStyles(theme => ({
  stickyCard: {
    position: "sticky",
    margin: theme.spacing(3),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(0),
    top: 20,
    transition: "all 0.3s ease",
  },
  stickyCardTitle: {
    marginTop: theme.spacing(1),
    fontFamily: "MabryProLight",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    letterSpacing: "0.5px",
    marginBottom: theme.spacing(3),
  },
  stickyCardGutter: {
    paddingLeft: theme.spacing(3),
    paddingRight: 0,
  },
  stickyListIcon: {
    minWidth: 20,
    color: "unset",
  },
  stickyCardContent: {
    height: "100%",
    paddingLeft: 0,
    paddingBottom: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  button: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#000",
    },
    transition: "all 0.3s ease",
  },
  denseList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const InvestStickyCard = ({
  title,
  servicesList,
  links,
  sections,
  selected,
}) => {
  const classes = useStyles()

  const [stickyCardHeight, setStickyCardHeight] = useState("88vh")

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        setStickyCardHeight("88vh")
      } else {
        setStickyCardHeight("95vh")
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  

  return (
    <Card className={classes.stickyCard} style={{ height: stickyCardHeight}}>
      <CardContent className={classes.stickyCardContent}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box pl={3}>
            <Typography variant="h6" className={classes.stickyCardTitle}>
              {title}
            </Typography>
            <List dense disablePadding>
              {servicesList.map((service, index) => (
                <ListItem
                  key={service}
                  disableGutters
                  classes={{
                    dense: classes.denseList,
                    button: classes.button,
                  }}
                  button
                >
                  <ReactScroll
                    to={sections[index]}
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    delay={10}
                  >
                    <ListItemText primaryTypographyProps={{ variant: "h6" }}>
                      {service}
                    </ListItemText>
                  </ReactScroll>
                </ListItem>
              ))}
            </List>
          </Box>
          <List>
            {links.map(({ label, to }, index) => (
              <>
                <ListItem
                  key={index}
                  component={LocalizedLink}
                  to={to}
                  divider={index >= links.length - 1 ? false : true}
                  classes={{
                    gutters: classes.stickyCardGutter,
                    button: classes.button,
                  }}
                  button
                  selected={to === selected ? true : false}
                >
                  <ListItemText>{label}</ListItemText>
                  <div className={classes.arrow}>
                    <ListItemIcon className={classes.stickyListIcon}>
                      <ArrowForward fontSize="small" />
                    </ListItemIcon>
                  </div>
                </ListItem>
              </>
            ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  )
}

export default InvestStickyCard
