import { createMuiTheme } from "@material-ui/core/styles"
import zhutravelTheme from "./zhutravelTheme"

const investTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    type: "dark",
    primary: {
      main: "#FFF",
    },
    secondary: {
      main: "#FAD578",
      dark: "#ffd54f",
      contrastText: "#010204",
    },
    text: {
      primary: "#FFF",
      secondary: "#FFF",
    },
    divider: "rgba(255,255,255,0.15)",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          color: "unset",
        },
        ul: {
          marginTop: zhutravelTheme.spacing(2),
          marginBottom: 0,
          paddingInline: 0,
          fontSize: "1rem",
        },
        li: {
          paddingBottom: zhutravelTheme.spacing(1.25),
          listStyleType: "square",
        },
        "li::marker": {
          color: "rgba(255,255,255,0.2)",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#1A1B1D",
      },
    },
    MuiTypography: {
      overline: {
        paddingBottom: zhutravelTheme.spacing(1.5),
      },
    },
    MuiCardContent: {
      root: {
        padding: zhutravelTheme.spacing(3),
      },
    },
    MuiCardActions: {
      root: {
        padding: zhutravelTheme.spacing(3),
        paddingTop: 0,
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          color: "#FAD578",
        },
      },
    },
  },
})

export default investTheme
