import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import {
  Button,
  Container,
  CssBaseline,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core"
import { makeStyles, ThemeProvider } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import { Element } from "react-scroll"
import Layout from "../components/Layout"
import investTheme from "../components/Layout/investTheme"
import Textfield from "../components/TextField"
import Footer from "../components/Footer"
import InvestStickyCard from "../components/InvestStickyCard"
import InvestStickyNav from "../components/InvestStickyNav"
import { SEO } from "../components/SEO"

const useStyles = makeStyles(theme => ({
  titleSection: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(12.5),
    },
  },
  pageSubtitle: {
    fontSize: "1.5rem",
    lineHeight: "32px",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(1.5),
    },
  },
  sectionWrapper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  listWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  denseList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  contactCardWrapper: {
    marginTop: theme.spacing(10),
  },
  importList: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(7),
    },
  },
  realEstateTitle: {
    fontSize: "1rem",
  },
  brokerItem: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
  delegationImage: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  button: {
    "&:hover": {
      color: investTheme.palette.secondary.main,
    },
  },
  media: {
    height: 376,
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  textWhite: {
    color: "white",
  },
}))

export const query = graphql`
  query($locale: String!) {
    allContentfulInvestPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          seoTitle
          seoDescription
          generalText {
            content {
              content {
                nodeType
                value
              }
              nodeType
            }
          }
          pageTitle
          pageDescription {
            pageDescription
          }
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          stickyCardTitle
          stickyCardServicesList
          stickyCardPages
          representSectionTitle
          representSectionMainTitle
          representSectionFirstListTitle
          representSectionFirstListItems
          representSectionSecondListTitle
          representSectionSecondListItems
          representSectionThirdListTitle
          representSectionThirdListItems
          representSectionFourthListTitle
          representSectionFourthListItems
          businessContactCardTitle
          businessContactCardDescription {
            businessContactCardDescription
            childMarkdownRemark {
              html
            }
          }
          businessContactCardImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          importSectionTitle
          importSectionMainTitle
          importSectionListItems

          delegationSectionTitle
          delegationSectionMainTitle
          delegationSectionListItems
          delegationSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          realStateSectionTitle
          realStateSectionMainTitle
          realStateSectionListTitles
          realStateSectionListDescriptions
          brokerSectionTitle
          brokerSectionMainTitle
          brokerSectionListTitles
          brokerSectionListDescriptions
          querySectionTopics
          querySectionDescription {
            querySectionDescription
            childMarkdownRemark {
              html
            }
          }
          importSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          realEstateSectionListImages {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          querySectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
        }
      }
    }
  }
`

const InvestScreen = ({
  location,
  pageContext,
  data: { allContentfulInvestPageModel, allContentfulLandingPageModel },
}) => {
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.investPages
  )
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const classes = useStyles()
  const {
    pageTitle,
    pageDescription,
    seoTitle,
    seoDescription,
    generalText,
    bannerImage,
    stickyCardTitle,
    stickyCardServicesList,
    representSectionTitle,
    representSectionMainTitle,
    representSectionFirstListTitle,
    representSectionFirstListItems,
    representSectionSecondListTitle,
    representSectionSecondListItems,
    representSectionThirdListTitle,
    representSectionThirdListItems,
    representSectionFourthListTitle,
    representSectionFourthListItems,
    businessContactCardDescription,
    businessContactCardTitle,
    businessContactCardImage,
    importSectionTitle,
    importSectionMainTitle,
    importSectionListItems,
    delegationSectionTitle,
    delegationSectionImage,
    realStateSectionTitle,
    realStateSectionMainTitle,
    realStateSectionListTitles,
    delegationSectionMainTitle,
    delegationSectionListItems,
    realStateSectionListDescriptions,
    brokerSectionTitle,
    brokerSectionMainTitle,
    brokerSectionListTitles,
    brokerSectionListDescriptions,
    importSectionImage,
    realEstateSectionListImages,
    querySectionImage,
    querySectionDescription,
  } = allContentfulInvestPageModel.edges[0].node
  const sections = [
    "business-representation",
    "import-export",
    "connect-delegations",
    "real-estate",
    "business-broker",
  ]
  const businessRepServices = [
    {
      heading: representSectionFirstListTitle,
      list: representSectionFirstListItems,
    },
    {
      heading: representSectionSecondListTitle,
      list: representSectionSecondListItems,
    },
    {
      heading: representSectionThirdListTitle,
      list: representSectionThirdListItems,
    },
    {
      heading: representSectionFourthListTitle,
      list: representSectionFourthListItems,
    },
  ]

  const stickyCardLinks = [
    { label: translations?.goldenVisa, to: "/GoldenVisa" },
    { label: translations?.whyPortugal, to: "/WhyPortugal" },
    {
      label: translations?.realEstateOpportunities,
      to: "/RealEstateOpportunities",
    },
    {
      label: translations?.investmentOpportunities,
      to: "/InvestmentOpportunities",
    },
  ]

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      backgroundcolor={"black"}
      Textcolor={"#FFFFFF"}
      selected={"invest"}
      button={"#FAD578"}
    >
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <div className={locale === "zh" && "chinese-version"}>
        <ThemeProvider theme={investTheme}>
          <CssBaseline />
          <Container>
            <Grid container>
              <Grid item md={8}>
                <Grid container className={classes.titleSection}>
                  <Grid item md={1} />
                  <Grid item xs={12} md={6}>
                    <Typography component="div" variant="h1">
                      {pageTitle}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.pageSubtitle}
                    >
                      {pageDescription.pageDescription}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <Grid item xs={12} md={9}>
                    <Img fluid={bannerImage.fluid} />
                  </Grid>
                </Grid>

                <Element
                  id="business-representation"
                  className={classes.sectionWrapper}
                >
                  <Typography variant="overline">
                    {representSectionTitle}
                  </Typography>
                  <Typography variant="h2">
                    {representSectionMainTitle}
                  </Typography>
                  <Grid container spacing={3}>
                    {businessRepServices.map(({ heading, list }, index) => (
                      <Grid key={heading} item xs={12} md={6} container>
                        <Grid item md={10} className={classes.listWrapper}>
                          <Typography variant="overline" color="secondary">
                            {(index + 1).toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}
                          </Typography>
                          <Typography variant="h6" component="h3">
                            {heading}
                          </Typography>
                          <ul>
                            {list.map(listItem => (
                              <li key={listItem}>{listItem}</li>
                            ))}
                          </ul>
                        </Grid>
                        <Grid item md={2} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    container
                    alignItems="flex-end"
                    className={classes.contactCardWrapper}
                  >
                    <Grid item xs={12} md={5}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6">
                            {businessContactCardTitle}
                          </Typography>
                          <Typography>
                            {
                              businessContactCardDescription.businessContactCardDescription
                            }
                          </Typography>
                          <Box mt={6} clone>
                            <Button variant="contained" color="secondary">
                              {translations?.contactUs}
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={4}>
                      <Img
                        fluid={businessContactCardImage.fluid}
                        style={{ transform: "translate(-60%, 15%)" }}
                      />
                    </Grid>
                  </Grid>
                </Element>

                <Divider />

                <Element id="import-export" className={classes.sectionWrapper}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                      <Img fluid={importSectionImage.fluid} />
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={1} />
                    </Hidden>
                    <Grid item xs={12} md={5}>
                      <Typography variant="overline">
                        {importSectionTitle}
                      </Typography>
                      <Typography variant="h2">
                        {importSectionMainTitle}
                      </Typography>
                      <ul className={classes.importList}>
                        {importSectionListItems.map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </Grid>
                  </Grid>
                </Element>

                <Divider />

                <Element
                  id="connect-delegations"
                  className={classes.sectionWrapper}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                      <Typography variant="overline">
                        {delegationSectionTitle}
                      </Typography>
                      <Typography variant="h2">
                        {delegationSectionMainTitle}
                      </Typography>
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={1} />
                    </Hidden>
                    <Grid item xs={12} md={5}>
                      <ul>
                        {delegationSectionListItems.map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </Grid>
                  </Grid>
                  <div className={classes.delegationImage}>
                    <Img fluid={delegationSectionImage.fluid} />
                  </div>
                </Element>

                <Divider />

                <Element id="real-estate" className={classes.sectionWrapper}>
                  <Typography variant="overline">
                    {realStateSectionTitle}
                  </Typography>
                  <Typography variant="h2">
                    {realStateSectionMainTitle}
                  </Typography>
                  <Grid container spacing={3} className={classes.listWrapper}>
                    {realStateSectionListTitles.map((title, index) => (
                      <Grid key={title} item xs={6} md={3}>
                        <Box width={1 / 3} pb={1.5}>
                          <Img
                            fluid={realEstateSectionListImages[index].fluid}
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          className={classes.realEstateTitle}
                        >
                          {title}
                        </Typography>
                        <Typography variant="subtitle2">
                          {realStateSectionListDescriptions[index]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Element>

                <Divider />

                <Element
                  id="business-broker"
                  className={classes.sectionWrapper}
                >
                  <Typography variant="overline">
                    {brokerSectionTitle}
                  </Typography>
                  <Typography variant="h2">{brokerSectionMainTitle}</Typography>
                  <Grid container spacing={3} className={classes.listWrapper}>
                    {brokerSectionListTitles.map((title, index) => (
                      <Grid
                        key={title}
                        item
                        xs={12}
                        md={4}
                        className={classes.brokerItem}
                      >
                        <Typography variant="overline" color="secondary">
                          {(index + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Typography>
                        <Box pt={2} pb={2}>
                          <Divider />
                        </Box>
                        <Typography variant="h6">{title}</Typography>
                        <Typography>
                          {brokerSectionListDescriptions[index]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Element>
              </Grid>

              <Hidden smDown>
                <Grid item md={4}>
                  <InvestStickyCard
                    title={stickyCardTitle}
                    servicesList={stickyCardServicesList}
                    links={stickyCardLinks}
                    sections={sections}
                  />
                </Grid>
              </Hidden>

              <InvestStickyNav
                title={stickyCardTitle}
                servicesList={stickyCardServicesList}
                links={stickyCardLinks}
                sections={sections}
              />
            </Grid>

            <Divider />
          </Container>
          <Textfield
            title={translations?.sendUsEnquiry}
            description={querySectionDescription.querySectionDescription}
            backgroundColor={"black"}
            customImage={querySectionImage}
            locale={pageContext.locale}
            defaultValue="Investment"
            cardVariant="contained"
          />
          <SEO
            seoData={{
              generalText,
            }}
            isGenText
          />
          <Container>
            <Divider />

            <Footer hidePartners />
          </Container>
        </ThemeProvider>
      </div>
    </Layout>
  )
}

export default InvestScreen
